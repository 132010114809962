<template>
    <tr>
        <td class="hide-sm" :class="{ empty: !item.group.name, selected: item.group?.selected }" @click="selectGroup">{{ item.group.name }}</td>
        <td :class="{ empty: !item.category.name, selected: item.category?.selected }" @click="selectCategory">{{ item.category.name }}</td>
        <td :class="{ empty: !item.item.name }" @click="selectLink">
            <span>{{ item.item.name }}</span>
        </td>
    </tr>
</template>
<script setup lang="ts">
import type { TableObjectFirstCol, TableObjectSecondCol } from '~/@types/Upakovka';

const props = defineProps<{
    item: { group: TableObjectFirstCol | {}; category: TableObjectSecondCol | {}; item: TableObjectSecondCol | {} };
}>();
const emits = defineEmits(['select:group', 'select:category']);

const selectLink = (): void => {
    if (!props.item.item.id) return;
    const elem = window.document.getElementById(`${props.item.item.id}_item`);
    elem && elem.scrollIntoView();
};
const selectCategory = () => {
    if (props.item.category) {
        emits('select:category', props.item.category?.selected ? null : props.item.category);
    }
};
const selectGroup = () => {
    if (props.item.group) {
        emits('select:group', props.item.group?.selected ? null : props.item.group);
    }
};
</script>

<style scoped lang="scss">
td {
    border-bottom: 1px solid #e4e6e7;
    padding: 12px 16px;
    text-align: left;
    vertical-align: top;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}
td.empty {
    border-bottom: unset;
}
td span {
    color: var(--other-blue-48538B, #48538b);
    text-decoration: none;
}
.selected {
    background: #48538b26;
}
@media screen and (max-width: 576px) {
    .hide-sm {
        display: none !important;
    }
}
</style>
